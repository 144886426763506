
//公共库
import { Component, Vue } from "vue-property-decorator";

//本地引入
import DTCls from "@/utils/datetime";
import { countryNoComList } from "@/utils";
import { getShopGiftAll } from "@/api/request/item";
import Pagination from "@/comp/Pagination/index.vue";
import { setPromotionExternal, getPromotionExternalList } from "@/api/request/promotion";

//组件
@Component({
  name: "PromotionSet",
  components: {
    Pagination,
  },
})

//界面函数
export default class extends Vue {
  //定义变量
  private list: any[] = []; //数据
  private total: number = 0; //总数
  private giftList: any[] = []; //礼包列表
  private countryList: any[] = []; //归属国家列表
  private listLoading: boolean = true; // 列表加载

  //请求数据
  private listQuery: any = {
    //页码
    row: 20,
    page: 1,

    //临时数据
    time: [], //时间
    useridStr: "", //用户ID

    //数据
    country: "",
    end_time: "", //结束时间
    start_time: "", //开始时间
    userid: undefined, //用户ID
  };

  //时间选择器参数
  get pickerOptions(): any {
    return {
      shortcuts: [
        {
          text: "今日",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getTodayStartTime()), new Date(DTCls.getTodayEndTime())]);
          },
        },
        {
          text: "昨日",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getYesterdayStartTime()), new Date(DTCls.getYesterdayEndTime())]);
          },
        },
        {
          text: "本周",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getCurWeekStartTime()), new Date(DTCls.getTodayEndTime())]);
          },
        },
        {
          text: "上周",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getLastWeekStartTime()), new Date(DTCls.getLastWeekEdnTime())]);
          },
        },
        {
          text: "本月",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getCurMonthStartTime()), new Date(DTCls.getTodayEndTime())]);
          },
        },
        {
          text: "上月",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getLastMonthStartTime()), new Date(DTCls.getLastMonthEndTime())]);
          },
        },
        {
          text: "最近一周",
          onClick(picker: any) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: "最近一个月",
          onClick(picker: any) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: "最近三个月",
          onClick(picker: any) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: "全部",
          onClick(picker: any) {
            picker.$emit("pick", []);
          },
        },
      ],
      disabledDate(time: Date) {
        return time.getTime() > Date.now();
      },
    };
  }

  //创建调用
  created() {
    //数据赋值
    this.countryList = countryNoComList;

    //获取礼包列表
    this.getGiftList();

    //获取列表
    this.getList();
  }

  //获取数据
  private async getList() {
    //显示等待
    this.listLoading = true;

    //注册时间赋值
    if (this.listQuery.time && this.listQuery.time.length == 2) {
      this.listQuery.end_time = `${this.listQuery.time[1]} 23:59:59`;
      this.listQuery.start_time = `${this.listQuery.time[0]} 00:00:00`;
    } else {
      this.listQuery.end_time = "";
      this.listQuery.start_time = "";
    }

    //其他赋值
    this.listQuery.userid = this.listQuery.useridStr == "" ? undefined : Number(this.listQuery.useridStr);

    //获取数据
    const { data } = await getPromotionExternalList(this.listQuery);

    //数据赋值
    this.list = data.list;
    this.total = data.total;

    //隐藏等待
    setTimeout(() => {
      this.listLoading = false;
    }, 0.5 * 1000);
  }

  //处理添加
  private handleAdd(): void {
    //数据赋值
    this.form = {
      //临时数据
      useridStr: "", //用户ID
      statusStr: "1", //状态
      commissionStr: "", //佣金比例
      gift_id_num: 0, //礼包ID

      //数据
      status: 1, //状态
      remark: "", //备注
      userid: 0, //用户ID
      id: undefined, //ID
      commission: 0, //佣金比例
      extend_code: "", //唯一标识
      gift_id: undefined, //礼包ID
    };

    //显示界面
    this.dialogShow = true;
  }

  //处理查询
  private handleSearch(): void {
    //数据赋值
    this.listQuery.page = 1;

    //获取数据
    this.getList();
  }

  //获取礼包列表
  private async getGiftList() {
    //显示等待
    this.listLoading = true;

    //获取数据
    const { data } = await getShopGiftAll();

    //数据赋值
    this.giftList = data;
    this.giftList.unshift({ id: 0, name: "请选择礼包" });
  }

  //处理编辑
  private handleEdit(row: any): void {
    //数据赋值
    this.form = {
      //临时数据
      useridStr: String(row.userid), //用户ID
      statusStr: String(row.status), //状态
      gift_id_num: Number(row.gift_id), //礼包ID
      commissionStr: String(row.commission), //佣金比例

      //数据
      id: row.id, //ID
      remark: row.remark, //备注
      status: row.status, //状态
      userid: row.userid, //用户ID
      gift_id: row.gift_id, //礼包ID
      commission: row.commission, //佣金比例
      extend_code: row.extend_code, //唯一标识
    };

    //显示界面
    this.dialogShow = true;
  }

  //获取归属国家名称
  private getCountryName(country: string): string {
    //没有国家属性
    if (country == "") {
      return "-";
    }

    //判断国家
    for (var i: number = 0; i < this.countryList.length; i++) {
      if (country == this.countryList[i].country) {
        return this.countryList[i].name;
      }
    }

    //反馈空
    return "";
  }

  //------------------------ 添加/编辑 ------------------------
  //表单数据
  private form: any = {
    //临时数据
    useridStr: "", //用户ID
    statusStr: "1", //状态
    commissionStr: "", //佣金比例
    gift_id_num: 0, //礼包ID

    //数据
    status: 1, //状态
    remark: "", //备注
    userid: 0, //用户ID
    id: undefined, //ID
    commission: 0, //佣金比例
    extend_code: "", //唯一标识
    gift_id: undefined, //礼包ID
  };

  //定义变量
  private dialogShow: boolean = false; //显示随画框
  private dialogLoading: boolean = false; //对话框等待

  //取消按钮
  private btnCancel(): void {
    //隐藏界面
    this.dialogShow = false;

    //获取列表
    this.getList();
  }

  //是否创建
  private isCreate(): boolean {
    return this.form.id === undefined;
  }

  //创建
  private async createData() {
    //异常判断
    if (this.form.gift_id_num == 0) return this.$message.error("请选择礼包");
    if (Number(this.form.useridStr) == 0) return this.$message.error("请输入正确的推广人ID");
    if (Number(this.form.commissionStr) <= 0 || Number(this.form.commissionStr) > 1) return this.$message.error("请输入正确的佣金比例");

    //显示等待
    this.dialogLoading = true;

    //数据赋值
    this.form.gift_id = this.form.gift_id_num; //礼包ID
    this.form.status = Number(this.form.statusStr); //状态
    this.form.userid = Number(this.form.useridStr); //用户ID
    this.form.commission = Number(this.form.commissionStr); //佣金比例

    //超时隐藏
    setTimeout(() => {
      //隐藏等待
      this.dialogLoading = false;
    }, 0.8 * 1000);

    //设置推广人
    const { data } = await setPromotionExternal(this.form);

    //隐藏等待
    setTimeout(() => {
      //隐藏等待
      this.dialogLoading = false;

      //隐藏对话框
      this.dialogShow = false;

      //获取数据
      this.getList();
    }, 0.5 * 1000);
  }

  //更新
  private async updateData() {
    //异常判断
    if (this.form.gift_id_num == 0) return this.$message.error("请选择礼包");
    if (Number(this.form.useridStr) == 0) return this.$message.error("请输入正确的推广人ID");
    if (Number(this.form.commissionStr) <= 0 || Number(this.form.commissionStr) > 1) return this.$message.error("请输入正确的佣金比例");

    //显示等待
    this.dialogLoading = true;

    //数据赋值
    this.form.gift_id = this.form.gift_id_num; //礼包ID
    this.form.status = Number(this.form.statusStr); //状态
    this.form.userid = Number(this.form.useridStr); //用户ID
    this.form.commission = Number(this.form.commissionStr); //佣金比例

    //超时隐藏
    setTimeout(() => {
      //隐藏等待
      this.dialogLoading = false;
    }, 0.8 * 1000);

    //设置推广人
    const { data } = await setPromotionExternal(this.form);

    //隐藏等待
    setTimeout(() => {
      //隐藏等待
      this.dialogLoading = false;

      //隐藏对话框
      this.dialogShow = false;

      //获取数据
      this.getList();
    }, 0.5 * 1000);
  }
}
